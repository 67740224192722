import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
//import ProfileView from '../views/ProfileView.vue'
import SiteView from '../views/SiteView.vue'
import EmailView from '../views/contents/email/EmailView.vue'
import TaskboardView from '../views/contents/taskboard/TaskboardView.vue'
import CreateView from '../views/contents/ticket/CreateView.vue'

const routes = [
  {
    path: '/',
    name: 'siteview',
    component: SiteView
  },
  {
    path: '/email',
    name: 'email',
    component: EmailView,
  },
  {
    path: '/taskboard',
    name: 'taskboard',
    component: TaskboardView,
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: CreateView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
