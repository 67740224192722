<template>
    
        <div class="col s12">
          <div class="container">
            <div class="section">
              <!-- Current balance & total transactions cards-->
              <div class="row vertical-modern-dashboard">
                <div class="col s12 m4 l4">
                  <!-- Current Balance -->
                  <div class="card animate fadeLeft">
                    <div class="card-content">
                      <h6 class="mb-0 mt-0 display-flex justify-content-between">Current Balance <i class="material-icons float-right">more_vert</i>
                      </h6>
                      <p class="medium-small">This billing cycle</p>
                      <div class="current-balance-container">
                        <div id="current-balance-donut-chart" class="current-balance-shadow"></div>
                      </div>
                      <h5 class="center-align">$ 50,150.00</h5>
                      <p class="medium-small center-align">Used balance this billing cycle</p>
                    </div>
                  </div>
                </div>
                <div class="col s12 m8 l8 animate fadeRight">
                  <!-- Total Transaction -->
                  <div class="card">
                    <div class="card-content">
                      <h4 class="card-title mb-0">Total Transaction <i class="material-icons float-right">more_vert</i>
                      </h4>
                      <p class="medium-small">This month transaction</p>
                      <div class="total-transaction-container">
                        <div id="total-transaction-line-chart" class="total-transaction-shadow"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--/ Current balance & total transactions cards-->
              <!-- User statistics & appointment cards-->
              <div class="row">
                <div class="col s12 l5">
                  <!-- User Statistics -->
                  <div class="card user-statistics-card animate fadeLeft">
                    <div class="card-content">
                      <h4 class="card-title mb-0">User Statistics <i class="material-icons float-right">more_vert</i>
                      </h4>
                      <div class="row">
                        <div class="col s12 m6">
                          <ul class="collection border-none mb-0">
                            <li class="collection-item avatar">
                              <i class="material-icons circle pink accent-2">trending_up</i>
                              <p class="medium-small">This year</p>
                              <h5 class="mt-0 mb-0">60%</h5>
                            </li>
                          </ul>
                        </div>
                        <div class="col s12 m6">
                          <ul class="collection border-none mb-0">
                            <li class="collection-item avatar">
                              <i class="material-icons circle purple accent-4">trending_down</i>
                              <p class="medium-small">Last year</p>
                              <h5 class="mt-0 mb-0">40%</h5>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="user-statistics-container">
                        <div id="user-statistics-bar-chart" class="user-statistics-shadow ct-golden-section"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col s12 l4">
                  <!-- Recent Buyers -->
                  <div class="card recent-buyers-card animate fadeUp">
                    <div class="card-content">
                      <h4 class="card-title mb-0">Recent Buyers <i class="material-icons float-right">more_vert</i>
                      </h4>
                      <p class="medium-small pt-2">Today</p>
                      <ul class="collection mb-0">
                        <li class="collection-item avatar">
                          <img src="/app-assets/images/avatar/avatar-7.png" alt="" class="circle" />
                          <p class="font-weight-600">John Doe</p>
                          <p class="medium-small">18, January 2019</p>
                          <a href="#!" class="secondary-content">
                            <i class="material-icons">star_border</i>
                          </a>
                        </li>
                        <li class="collection-item avatar">
                          <img src="/app-assets/images/avatar/avatar-3.png" alt="" class="circle" />
                          <p class="font-weight-600">Adam Garza</p>
                          <p class="medium-small">20, January 2019</p>
                          <a href="#!" class="secondary-content">
                            <i class="material-icons">star_border</i>
                          </a>
                        </li>
                        <li class="collection-item avatar">
                          <img src="/app-assets/images/avatar/avatar-5.png" alt="" class="circle" />
                          <p class="font-weight-600">Jennifer Rice</p>
                          <p class="medium-small">25, January 2019</p>
                          <a href="#!" class="secondary-content">
                            <i class="material-icons">star_border</i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col s12 l3">
                  <div class="card animate fadeRight">
                    <div class="card-content">
                      <h4 class="card-title mb-0">Conversion Ratio</h4>
                      <div class="conversion-ration-container mt-8">
                        <div id="conversion-ration-bar-chart" class="conversion-ration-shadow"></div>
                      </div>
                      <p class="medium-small center-align">This month conversion ratio</p>
                      <h5 class="center-align mb-0 mt-0">62%</h5>
                    </div>
                  </div>
                </div>
              </div>
              <!--/ Current balance & appointment cards-->
              <div class="row">
                <div class="col s12 m6 l4">
                  <div class="card padding-4 animate fadeLeft">
                    <div class="row">
                      <div class="col s5 m5">
                        <h5 class="mb-0">1885</h5>
                        <p class="no-margin">New</p>
                        <p class="mb-0 pt-8">1,12,900</p>
                      </div>
                      <div class="col s7 m7 right-align">
                        <i class="material-icons background-round mt-5 mb-5 gradient-45deg-purple-amber gradient-shadow white-text">perm_identity</i>
                        <p class="mb-0">Total Clients</p>
                      </div>
                    </div>
                  </div>
                  <div id="chartjs" class="card pt-0 pb-0 animate fadeLeft">
                    <div class="dashboard-revenue-wrapper padding-2 ml-2">
                      <span class="new badge gradient-45deg-indigo-purple gradient-shadow mt-2 mr-2">+ $900</span>
                      <p class="mt-2 mb-0 font-weight-600">Today's revenue</p>
                      <p class="no-margin grey-text lighten-3">$40,512 avg</p>
                      <h5>$ 22,300</h5>
                    </div>
                    <div class="sample-chart-wrapper card-gradient-chart">
                      <canvas id="custom-line-chart-sample-three" class="center"></canvas>
                    </div>
                  </div>
                </div>
                <div class="col s12 m6 l8">
                  <div class="card subscriber-list-card animate fadeRight">
                    <div class="card-content pb-1">
                      <h4 class="card-title mb-0">Subscriber List <i class="material-icons float-right">more_vert</i>
                      </h4>
                    </div>
                    <table class="subscription-table responsive-table highlight">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Company</th>
                          <th>Start Date</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Michael Austin</td>
                          <td>ABC Fintech LTD.</td>
                          <td>Jan 1,2019</td>
                          <td>
                            <span class="badge pink lighten-5 pink-text text-accent-2">Close</span>
                          </td>
                          <td>$ 1000.00</td>
                          <td class="center-align">
                            <a href="#">
                              <i class="material-icons pink-text">clear</i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Aldin Rakić</td>
                          <td>ACME Pvt LTD.</td>
                          <td>Jan 10,2019</td>
                          <td>
                            <span class="badge green lighten-5 green-text text-accent-4">Open</span>
                          </td>
                          <td>$ 3000.00</td>
                          <td class="center-align">
                            <a href="#">
                              <i class="material-icons pink-text">clear</i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>İris Yılmaz</td>
                          <td>Collboy Tech LTD.</td>
                          <td>Jan 12,2019</td>
                          <td>
                            <span class="badge green lighten-5 green-text text-accent-4">Open</span>
                          </td>
                          <td>$ 2000.00</td>
                          <td class="center-align">
                            <a href="#">
                              <i class="material-icons pink-text">clear</i>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Lidia Livescu</td>
                          <td>My Fintech LTD.</td>
                          <td>Jan 14,2019</td>
                          <td>
                            <span class="badge pink lighten-5 pink-text text-accent-2">Close</span>
                          </td>
                          <td>$ 1100.00</td>
                          <td class="center-align">
                            <a href="#">
                              <i class="material-icons pink-text">clear</i>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- START RIGHT SIDEBAR NAV -->
            <aside id="right-sidebar-nav">
              <div id="slide-out-right" class="slide-out-right-sidenav sidenav rightside-navigation">
                <div class="row">
                  <div class="slide-out-right-title">
                    <div class="col s12 border-bottom-1 pb-0 pt-1">
                      <div class="row">
                        <div class="col s2 pr-0 center">
                          <i class="material-icons vertical-text-middle">
                            <a href="#" class="sidenav-close">clear</a>
                          </i>
                        </div>
                        <div class="col s10 pl-0">
                          <ul class="tabs">
                            <li class="tab col s4 p-0">
                              <a href="#messages" class="active">
                                <span>Messages</span>
                              </a>
                            </li>
                            <li class="tab col s4 p-0">
                              <a href="#settings">
                                <span>Settings</span>
                              </a>
                            </li>
                            <li class="tab col s4 p-0">
                              <a href="#activity">
                                <span>Activity</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="slide-out-right-body row pl-3">
                    <div id="messages" class="col s12 pb-0">
                      <div class="collection border-none mb-0">
                        <input class="header-search-input mt-4 mb-2" type="text" name="Search" placeholder="Search Messages" />
                        <ul class="collection right-sidebar-chat p-0 mb-0">
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Elizabeth Elliott</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Thank you</p>
                            </div>
                            <span class="secondary-content medium-small">5.00 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-1.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Mary Adams</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Hello Boo</p>
                            </div>
                            <span class="secondary-content medium-small">4.14 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-off avatar-50">
                              <img src="/app-assets/images/avatar/avatar-2.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Caleb Richards</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Hello Boo</p>
                            </div>
                            <span class="secondary-content medium-small">4.14 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-3.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Caleb Richards</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Keny !</p>
                            </div>
                            <span class="secondary-content medium-small">9.00 PM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-4.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">June Lane</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Ohh God</p>
                            </div>
                            <span class="secondary-content medium-small">4.14 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-off avatar-50">
                              <img src="/app-assets/images/avatar/avatar-5.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Edward Fletcher</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Love you</p>
                            </div>
                            <span class="secondary-content medium-small">5.15 PM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-6.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Crystal Bates</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Can we</p>
                            </div>
                            <span class="secondary-content medium-small">8.00 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-off avatar-50">
                              <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Nathan Watts</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Great!</p>
                            </div>
                            <span class="secondary-content medium-small">9.53 PM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-off avatar-50">
                              <img src="/app-assets/images/avatar/avatar-8.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Willard Wood</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Do it</p>
                            </div>
                            <span class="secondary-content medium-small">4.20 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-1.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Ronnie Ellis</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Got that</p>
                            </div>
                            <span class="secondary-content medium-small">5.20 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-9.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Daniel Russell</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Thank you</p>
                            </div>
                            <span class="secondary-content medium-small">12.00 AM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-off avatar-50">
                              <img src="/app-assets/images/avatar/avatar-10.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Sarah Graves</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Okay you</p>
                            </div>
                            <span class="secondary-content medium-small">11.14 PM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-off avatar-50">
                              <img src="/app-assets/images/avatar/avatar-11.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Andrew Hoffman</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Can do</p>
                            </div>
                            <span class="secondary-content medium-small">7.30 PM</span>
                          </li>
                          <li class="collection-item right-sidebar-chat-item sidenav-trigger display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                            <span class="avatar-status avatar-online avatar-50">
                              <img src="/app-assets/images/avatar/avatar-12.png" alt="avatar" />
                              <i></i>
                            </span>
                            <div class="user-content">
                              <h6 class="line-height-0">Camila Lynch</h6>
                              <p class="medium-small blue-grey-text text-lighten-3 pt-3">Leave it</p>
                            </div>
                            <span class="secondary-content medium-small">2.00 PM</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div id="settings" class="col s12">
                      <p class="setting-header mt-8 mb-3 ml-5 font-weight-900">GENERAL SETTINGS</p>
                      <ul class="collection border-none">
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Notifications</span>
                            <div class="switch right">
                              <label>
                                <input checked type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Show recent activity</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Show recent activity</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Show Task statistics</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Show your emails</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Email Notifications</span>
                            <div class="switch right">
                              <label>
                                <input checked type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <p class="setting-header mt-7 mb-3 ml-5 font-weight-900">SYSTEM SETTINGS</p>
                      <ul class="collection border-none">
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>System Logs</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Error Reporting</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Applications Logs</span>
                            <div class="switch right">
                              <label>
                                <input checked type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Backup Servers</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                        <li class="collection-item border-none">
                          <div class="m-0">
                            <span>Audit Logs</span>
                            <div class="switch right">
                              <label>
                                <input type="checkbox" />
                                <span class="lever"></span>
                              </label>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div id="activity" class="col s12">
                      <div class="activity">
                        <p class="mt-5 mb-0 ml-5 font-weight-900">SYSTEM LOGS</p>
                        <ul class="widget-timeline mb-0">
                          <li class="timeline-items timeline-icon-green active">
                            <div class="timeline-time">Today</div>
                            <h6 class="timeline-title">Homepage mockup design</h6>
                            <p class="timeline-text">Melissa liked your activity.</p>
                            <div class="timeline-content orange-text">Important</div>
                          </li>
                          <li class="timeline-items timeline-icon-cyan active">
                            <div class="timeline-time">10 min</div>
                            <h6 class="timeline-title">Melissa liked your activity Drinks.</h6>
                            <p class="timeline-text">Here are some news feed interactions concepts.</p>
                            <div class="timeline-content green-text">Resolved</div>
                          </li>
                          <li class="timeline-items timeline-icon-red active">
                            <div class="timeline-time">30 mins</div>
                            <h6 class="timeline-title">12 new users registered</h6>
                            <p class="timeline-text">Here are some news feed interactions concepts.</p>
                            <div class="timeline-content">
                              <img src="/app-assets/images/icon/pdf.png" alt="document" height="30" width="25" class="mr-1">Registration.doc
                            </div>
                          </li>
                          <li class="timeline-items timeline-icon-indigo active">
                            <div class="timeline-time">2 Hrs</div>
                            <h6 class="timeline-title">Tina is attending your activity</h6>
                            <p class="timeline-text">Here are some news feed interactions concepts.</p>
                            <div class="timeline-content">
                              <img src="/app-assets/images/icon/pdf.png" alt="document" height="30" width="25" class="mr-1">Activity.doc
                            </div>
                          </li>
                          <li class="timeline-items timeline-icon-orange">
                            <div class="timeline-time">5 hrs</div>
                            <h6 class="timeline-title">Josh is now following you</h6>
                            <p class="timeline-text">Here are some news feed interactions concepts.</p>
                            <div class="timeline-content red-text">Pending</div>
                          </li>
                        </ul>
                        <p class="mt-5 mb-0 ml-5 font-weight-900">APPLICATIONS LOGS</p>
                        <ul class="widget-timeline mb-0">
                          <li class="timeline-items timeline-icon-green active">
                            <div class="timeline-time">Just now</div>
                            <h6 class="timeline-title">New order received urgent</h6>
                            <p class="timeline-text">Melissa liked your activity.</p>
                            <div class="timeline-content orange-text">Important</div>
                          </li>
                          <li class="timeline-items timeline-icon-cyan active">
                            <div class="timeline-time">05 min</div>
                            <h6 class="timeline-title">System shutdown.</h6>
                            <p class="timeline-text">Here are some news feed interactions concepts.</p>
                            <div class="timeline-content blue-text">Urgent</div>
                          </li>
                          <li class="timeline-items timeline-icon-red">
                            <div class="timeline-time">20 mins</div>
                            <h6 class="timeline-title">Database overloaded 89%</h6>
                            <p class="timeline-text">Here are some news feed interactions concepts.</p>
                            <div class="timeline-content">
                              <img src="/app-assets/images/icon/pdf.png" alt="document" height="30" width="25" class="mr-1">Database-log.doc
                            </div>
                          </li>
                        </ul>
                        <p class="mt-5 mb-0 ml-5 font-weight-900">SERVER LOGS</p>
                        <ul class="widget-timeline mb-0">
                          <li class="timeline-items timeline-icon-green active">
                            <div class="timeline-time">10 min</div>
                            <h6 class="timeline-title">System error</h6>
                            <p class="timeline-text">Melissa liked your activity.</p>
                            <div class="timeline-content red-text">Error</div>
                          </li>
                          <li class="timeline-items timeline-icon-cyan">
                            <div class="timeline-time">1 min</div>
                            <h6 class="timeline-title">Production server down.</h6>
                            <p class="timeline-text">Here are some news feed interactions concepts.</p>
                            <div class="timeline-content blue-text">Urgent</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Slide Out Chat -->
              <ul id="slide-out-chat" class="sidenav slide-out-right-sidenav-chat">
                <li class="center-align pt-2 pb-2 sidenav-close chat-head">
                  <a href="#!">
                    <i class="material-icons mr-0">chevron_left</i>Elizabeth Elliott </a>
                </li>
                <li class="chat-body">
                  <ul class="collection">
                    <li class="collection-item display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                      <span class="avatar-status avatar-online avatar-50">
                        <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                      </span>
                      <div class="user-content speech-bubble">
                        <p class="medium-small">hello!</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat">
                      <div class="user-content speech-bubble-right">
                        <p class="medium-small">How can we help? We're here for you!</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                      <span class="avatar-status avatar-online avatar-50">
                        <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                      </span>
                      <div class="user-content speech-bubble">
                        <p class="medium-small">I am looking for the best admin template.?</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat">
                      <div class="user-content speech-bubble-right">
                        <p class="medium-small">Materialize admin is the responsive materializecss admin template.</p>
                      </div>
                    </li>
                    <li class="collection-item display-grid width-100 center-align">
                      <p>8:20 a.m.</p>
                    </li>
                    <li class="collection-item display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                      <span class="avatar-status avatar-online avatar-50">
                        <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                      </span>
                      <div class="user-content speech-bubble">
                        <p class="medium-small">Ohh! very nice</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat">
                      <div class="user-content speech-bubble-right">
                        <p class="medium-small">Thank you.</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                      <span class="avatar-status avatar-online avatar-50">
                        <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                      </span>
                      <div class="user-content speech-bubble">
                        <p class="medium-small">How can I purchase it?</p>
                      </div>
                    </li>
                    <li class="collection-item display-grid width-100 center-align">
                      <p>9:00 a.m.</p>
                    </li>
                    <li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat">
                      <div class="user-content speech-bubble-right">
                        <p class="medium-small">From ThemeForest.</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat">
                      <div class="user-content speech-bubble-right">
                        <p class="medium-small">Only $24</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                      <span class="avatar-status avatar-online avatar-50">
                        <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                      </span>
                      <div class="user-content speech-bubble">
                        <p class="medium-small">Ohh! Thank you.</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar pl-5 pb-0" data-target="slide-out-chat">
                      <span class="avatar-status avatar-online avatar-50">
                        <img src="/app-assets/images/avatar/avatar-7.png" alt="avatar" />
                      </span>
                      <div class="user-content speech-bubble">
                        <p class="medium-small">I will purchase it for sure.</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat">
                      <div class="user-content speech-bubble-right">
                        <p class="medium-small">Great, Feel free to get in touch on</p>
                      </div>
                    </li>
                    <li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat">
                      <div class="user-content speech-bubble-right">
                        <p class="medium-small">https://pixinvent.ticksy.com/</p>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="center-align chat-footer">
                  <form class="col s12" onsubmit="slideOutChat()" action="javascript:void(0);">
                    <div class="input-field">
                      <input id="icon_prefix" type="text" class="search" />
                      <label for="icon_prefix">Type here..</label>
                      <a onclick="slideOutChat()">
                        <i class="material-icons prefix">send</i>
                      </a>
                    </div>
                  </form>
                </li>
              </ul>
            </aside>
            <!-- END RIGHT SIDEBAR NAV -->
            <!-- Intro -->
            <div id="intro">
              <div class="row">
                <div class="col s12">
                  <div id="img-modal" class="modal white">
                    <div class="modal-content">
                      <div class="bg-img-div"></div>
                      <p class="modal-header right modal-close"> Skip Intro <span class="right">
                          <i class="material-icons right-align">clear</i>
                        </span>
                      </p>
                      <div class="carousel carousel-slider center intro-carousel">
                        <div class="carousel-fixed-item center middle-indicator">
                          <div class="left">
                            <button class="movePrevCarousel middle-indicator-text btn btn-flat purple-text waves-effect waves-light btn-prev">
                              <i class="material-icons">navigate_before</i>
                              <span class="hide-on-small-only">Prev</span>
                            </button>
                          </div>
                          <div class="right">
                            <button class=" moveNextCarousel middle-indicator-text btn btn-flat purple-text waves-effect waves-light btn-next">
                              <span class="hide-on-small-only">Next</span>
                              <i class="material-icons">navigate_next</i>
                            </button>
                          </div>
                        </div>
                        <div class="carousel-item slide-1">
                          <img src="/app-assets/images/gallery/intro-slide-1.png" alt="" class="responsive-img animated fadeInUp slide-1-img">
                          <h5 class="intro-step-title mt-7 center animated fadeInUp">Welcome to Materialize</h5>
                          <p class="intro-step-text mt-5 animated fadeInUp">Materialize is a Material Design Admin Template is the excellent responsive google material design inspired multipurpose admin template. Materialize has a huge collection of material design animation & widgets, UI Elements.</p>
                        </div>
                        <div class="carousel-item slide-2">
                          <img src="/app-assets/images/gallery/intro-features.png" alt="" class="responsive-img slide-2-img">
                          <h5 class="intro-step-title mt-7 center">Example Request Information</h5>
                          <p class="intro-step-text mt-5">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aperiam deserunt nulla repudiandae odit quisquam incidunt, maxime explicabo.</p>
                          <div class="row">
                            <div class="col s6">
                              <div class="input-field">
                                <label for="first_name">Name</label>
                                <input placeholder="Name" id="first_name" type="text" class="validate">
                              </div>
                            </div>
                            <div class="col s6">
                              <div class="input-field">
                                <select>
                                  <option value="" disabled selected>Choose your option</option>
                                  <option value="1">Option 1</option>
                                  <option value="2">Option 2</option>
                                  <option value="3">Option 3</option>
                                </select>
                                <label>Materialize Select</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="carousel-item slide-3">
                          <img src="/app-assets/images/gallery/intro-app.png" alt="" class="responsive-img slide-1-img">
                          <h5 class="intro-step-title mt-7 center">Showcase App Features</h5>
                          <div class="row">
                            <div class="col m5 offset-m1 s12">
                              <ul class="feature-list left-align">
                                <li>
                                  <i class="material-icons">check</i> Email Application
                                </li>
                                <li>
                                  <i class="material-icons">check</i> Chat Application
                                </li>
                                <li>
                                  <i class="material-icons">check</i> Todo Application
                                </li>
                              </ul>
                            </div>
                            <div class="col m6 s12">
                              <ul class="feature-list left-align">
                                <li>
                                  <i class="material-icons">check</i>Contacts Application
                                </li>
                                <li>
                                  <i class="material-icons">check</i>Full Calendar
                                </li>
                                <li>
                                  <i class="material-icons">check</i> Ecommerce Application
                                </li>
                              </ul>
                            </div>
                            <div class="row">
                              <div class="col s12 center">
                                <button class="get-started btn waves-effect waves-light gradient-45deg-purple-deep-orange mt-3 modal-close">Get Started</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- / Intro -->
          </div>
          <div class="content-overlay"></div>
        </div>
      
</template>