<template>
  <!--<nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> | 
    <router-link to="/profile">Profile</router-link>
  </nav>
  <router-view/>-->

  <!--Test-->
  
    <!--Header-->
   
    <!-- BEGIN: Header-->
    <HeaderSite />
    <!-- END: Header-->
    <ul class="display-none" id="default-search-main">
      <li class="auto-suggestion-title">
        <a class="collection-item" href="#">
          <h6 class="search-title">FILES</h6>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img src="/app-assets/images/icon/pdf-image.png" width="24" height="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">Two new item submitted</span>
                <small class="grey-text">Marketing Manager</small>
              </div>
            </div>
            <div class="status">
              <small class="grey-text">17kb</small>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img src="/app-assets/images/icon/doc-image.png" width="24" height="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">52 Doc file Generator</span>
                <small class="grey-text">FontEnd Developer</small>
              </div>
            </div>
            <div class="status">
              <small class="grey-text">550kb</small>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img src="/app-assets/images/icon/xls-image.png" width="24" height="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">25 Xls File Uploaded</span>
                <small class="grey-text">Digital Marketing Manager</small>
              </div>
            </div>
            <div class="status">
              <small class="grey-text">20kb</small>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img src="/app-assets/images/icon/jpg-image.png" width="24" height="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">Anna Strong</span>
                <small class="grey-text">Web Designer</small>
              </div>
            </div>
            <div class="status">
              <small class="grey-text">37kb</small>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion-title">
        <a class="collection-item" href="#">
          <h6 class="search-title">MEMBERS</h6>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img class="circle" src="/app-assets/images/avatar/avatar-7.png" width="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">John Doe</span>
                <small class="grey-text">UI designer</small>
              </div>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img class="circle" src="/app-assets/images/avatar/avatar-8.png" width="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">Michal Clark</span>
                <small class="grey-text">FontEnd Developer</small>
              </div>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img class="circle" src="/app-assets/images/avatar/avatar-10.png" width="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">Milena Gibson</span>
                <small class="grey-text">Digital Marketing</small>
              </div>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a class="collection-item" href="#">
          <div class="display-flex">
            <div class="display-flex align-item-center flex-grow-1">
              <div class="avatar">
                <img class="circle" src="/app-assets/images/avatar/avatar-12.png" width="30" alt="sample image">
              </div>
              <div class="member-info display-flex flex-column">
                <span class="black-text">Anna Strong</span>
                <small class="grey-text">Web Designer</small>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <ul class="display-none" id="page-search-title">
      <li class="auto-suggestion-title">
        <a class="collection-item" href="#">
          <h6 class="search-title">PAGES</h6>
        </a>
      </li>
    </ul>
    <ul class="display-none" id="search-not-found">
      <li class="auto-suggestion">
        <a class="collection-item display-flex align-items-center" href="#">
          <span class="material-icons">error_outline</span>
          <span class="member-info">No results found.</span>
        </a>
      </li>
    </ul>
    <!-- BEGIN: SideNav-->
    <NavSite />
    <!-- END: SideNav-->
    <!-- BEGIN: Page Main <SiteView />-->
    <div id="main">
      <div class="row">
        <div class="content-wrapper-before gradient-45deg-indigo-purple"></div>
        <router-view/>
      </div>
    </div>

    <!-- END: Page Main-->
    <!-- Theme Customizer -->
    <!--<a href="#" data-target="theme-cutomizer-out" class="btn btn-customizer pink accent-2 white-text sidenav-trigger theme-cutomizer-trigger">
      <i class="material-icons">settings</i>
    </a>
    <div id="theme-cutomizer-out" class="theme-cutomizer sidenav row">
      <div class="col s12">
        <a class="sidenav-close" href="#!">
          <i class="material-icons">close</i>
        </a>
        <h5 class="theme-cutomizer-title">Theme Customizer</h5>
        <p class="medium-small">Customize & Preview in Real Time</p>
        <div class="menu-options">
          <h6 class="mt-6">Menu Options</h6>
          <hr class="customize-devider" />
          <div class="menu-options-form row">
            <div class="input-field col s12 menu-color mb-0">
              <p class="mt-0">Menu Color</p>
              <div class="gradient-color center-align">
                <span class="menu-color-option gradient-45deg-indigo-blue" data-color="gradient-45deg-indigo-blue"></span>
                <span class="menu-color-option gradient-45deg-purple-deep-orange" data-color="gradient-45deg-purple-deep-orange"></span>
                <span class="menu-color-option gradient-45deg-light-blue-cyan" data-color="gradient-45deg-light-blue-cyan"></span>
                <span class="menu-color-option gradient-45deg-purple-amber" data-color="gradient-45deg-purple-amber"></span>
                <span class="menu-color-option gradient-45deg-purple-deep-purple" data-color="gradient-45deg-purple-deep-purple"></span>
                <span class="menu-color-option gradient-45deg-deep-orange-orange" data-color="gradient-45deg-deep-orange-orange"></span>
                <span class="menu-color-option gradient-45deg-green-teal" data-color="gradient-45deg-green-teal"></span>
                <span class="menu-color-option gradient-45deg-indigo-light-blue" data-color="gradient-45deg-indigo-light-blue"></span>
                <span class="menu-color-option gradient-45deg-red-pink" data-color="gradient-45deg-red-pink"></span>
              </div>
              <div class="solid-color center-align">
                <span class="menu-color-option red" data-color="red"></span>
                <span class="menu-color-option purple" data-color="purple"></span>
                <span class="menu-color-option pink" data-color="pink"></span>
                <span class="menu-color-option deep-purple" data-color="deep-purple"></span>
                <span class="menu-color-option cyan" data-color="cyan"></span>
                <span class="menu-color-option teal" data-color="teal"></span>
                <span class="menu-color-option light-blue" data-color="light-blue"></span>
                <span class="menu-color-option amber darken-3" data-color="amber darken-3"></span>
                <span class="menu-color-option brown darken-2" data-color="brown darken-2"></span>
              </div>
            </div>
            <div class="input-field col s12 menu-bg-color mb-0">
              <p class="mt-0">Menu Background Color</p>
              <div class="gradient-color center-align">
                <span class="menu-bg-color-option gradient-45deg-indigo-blue" data-color="gradient-45deg-indigo-blue"></span>
                <span class="menu-bg-color-option gradient-45deg-purple-deep-orange" data-color="gradient-45deg-purple-deep-orange"></span>
                <span class="menu-bg-color-option gradient-45deg-light-blue-cyan" data-color="gradient-45deg-light-blue-cyan"></span>
                <span class="menu-bg-color-option gradient-45deg-purple-amber" data-color="gradient-45deg-purple-amber"></span>
                <span class="menu-bg-color-option gradient-45deg-purple-deep-purple" data-color="gradient-45deg-purple-deep-purple"></span>
                <span class="menu-bg-color-option gradient-45deg-deep-orange-orange" data-color="gradient-45deg-deep-orange-orange"></span>
                <span class="menu-bg-color-option gradient-45deg-green-teal" data-color="gradient-45deg-green-teal"></span>
                <span class="menu-bg-color-option gradient-45deg-indigo-light-blue" data-color="gradient-45deg-indigo-light-blue"></span>
                <span class="menu-bg-color-option gradient-45deg-red-pink" data-color="gradient-45deg-red-pink"></span>
              </div>
              <div class="solid-color center-align">
                <span class="menu-bg-color-option red" data-color="red"></span>
                <span class="menu-bg-color-option purple" data-color="purple"></span>
                <span class="menu-bg-color-option pink" data-color="pink"></span>
                <span class="menu-bg-color-option deep-purple" data-color="deep-purple"></span>
                <span class="menu-bg-color-option cyan" data-color="cyan"></span>
                <span class="menu-bg-color-option teal" data-color="teal"></span>
                <span class="menu-bg-color-option light-blue" data-color="light-blue"></span>
                <span class="menu-bg-color-option amber darken-3" data-color="amber darken-3"></span>
                <span class="menu-bg-color-option brown darken-2" data-color="brown darken-2"></span>
              </div>
            </div>
            <div class="input-field col s12">
              <div class="switch"> Menu Dark <label class="float-right">
                  <input class="menu-dark-checkbox" type="checkbox" />
                  <span class="lever ml-0"></span>
                </label>
              </div>
            </div>
            <div class="input-field col s12">
              <div class="switch"> Menu Collapsed <label class="float-right">
                  <input class="menu-collapsed-checkbox" type="checkbox" />
                  <span class="lever ml-0"></span>
                </label>
              </div>
            </div>
            <div class="input-field col s12">
              <div class="switch">
                <p class="mt-0">Menu Selection</p>
                <label>
                  <input class="menu-selection-radio with-gap" value="sidenav-active-square" name="menu-selection" type="radio" />
                  <span>Square</span>
                </label>
                <label>
                  <input class="menu-selection-radio with-gap" value="sidenav-active-rounded" name="menu-selection" type="radio" />
                  <span>Rounded</span>
                </label>
                <label>
                  <input class="menu-selection-radio with-gap" value="" name="menu-selection" type="radio" />
                  <span>Normal</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <h6 class="mt-6">Navbar Options</h6>
        <hr class="customize-devider" />
        <div class="navbar-options row">
          <div class="input-field col s12 navbar-color mb-0">
            <p class="mt-0">Navbar Color</p>
            <div class="gradient-color center-align">
              <span class="navbar-color-option gradient-45deg-indigo-blue" data-color="gradient-45deg-indigo-blue"></span>
              <span class="navbar-color-option gradient-45deg-purple-deep-orange" data-color="gradient-45deg-purple-deep-orange"></span>
              <span class="navbar-color-option gradient-45deg-light-blue-cyan" data-color="gradient-45deg-light-blue-cyan"></span>
              <span class="navbar-color-option gradient-45deg-purple-amber" data-color="gradient-45deg-purple-amber"></span>
              <span class="navbar-color-option gradient-45deg-purple-deep-purple" data-color="gradient-45deg-purple-deep-purple"></span>
              <span class="navbar-color-option gradient-45deg-deep-orange-orange" data-color="gradient-45deg-deep-orange-orange"></span>
              <span class="navbar-color-option gradient-45deg-green-teal" data-color="gradient-45deg-green-teal"></span>
              <span class="navbar-color-option gradient-45deg-indigo-light-blue" data-color="gradient-45deg-indigo-light-blue"></span>
              <span class="navbar-color-option gradient-45deg-red-pink" data-color="gradient-45deg-red-pink"></span>
            </div>
            <div class="solid-color center-align">
              <span class="navbar-color-option red" data-color="red"></span>
              <span class="navbar-color-option purple" data-color="purple"></span>
              <span class="navbar-color-option pink" data-color="pink"></span>
              <span class="navbar-color-option deep-purple" data-color="deep-purple"></span>
              <span class="navbar-color-option cyan" data-color="cyan"></span>
              <span class="navbar-color-option teal" data-color="teal"></span>
              <span class="navbar-color-option light-blue" data-color="light-blue"></span>
              <span class="navbar-color-option amber darken-3" data-color="amber darken-3"></span>
              <span class="navbar-color-option brown darken-2" data-color="brown darken-2"></span>
            </div>
          </div>
          <div class="input-field col s12">
            <div class="switch"> Navbar Dark <label class="float-right">
                <input class="navbar-dark-checkbox" type="checkbox" />
                <span class="lever ml-0"></span>
              </label>
            </div>
          </div>
          <div class="input-field col s12">
            <div class="switch"> Navbar Fixed <label class="float-right">
                <input class="navbar-fixed-checkbox" type="checkbox" checked />
                <span class="lever ml-0"></span>
              </label>
            </div>
          </div>
        </div>
        <h6 class="mt-6">Footer Options</h6>
        <hr class="customize-devider" />
        <div class="navbar-options row">
          <div class="input-field col s12">
            <div class="switch"> Footer Dark <label class="float-right">
                <input class="footer-dark-checkbox" type="checkbox" />
                <span class="lever ml-0"></span>
              </label>
            </div>
          </div>
          <div class="input-field col s12">
            <div class="switch"> Footer Fixed <label class="float-right">
                <input class="footer-fixed-checkbox" type="checkbox" />
                <span class="lever ml-0"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <!--/ Theme Customizer -->
    <!-- Call Theme-->
   
    
    <!--End Call-->
   
    <!-- BEGIN: Footer-->
    
    <FooterSite />
    
    <!-- END: Footer-->
</template>

<script>

import FooterSite from "./components/FooterSite.vue";
import HeaderSite from './components/HeaderSite.vue'
import NavSite from './components/NavSite.vue'
//views
//import SiteView from './views/SiteView.vue'
//End  
export default {
        name: 'HeaderLayout',
        components:{
    HeaderSite,
    NavSite,
    FooterSite
},
};

</script>


